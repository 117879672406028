import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import axios from 'axios';

import ProtectedRoute from './ProtectedRoute';
import Landing from './Landing';
import Header from './Header';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import './App.css';
import { AuthProvider } from './authContext';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [passwordModal, setPasswordModal] = useState({ visible: false, view: '' });
  const [password, setPassword] = useState('');
  const [showMessageModal, setShowMessageModal] = useState(false);

  const googleTranslateRef = useRef(null);

    // Check authentication status on app load
    useEffect(() => {
      const checkAuth = async () => {
        try {
          const response = await axios.get('http://localhost:8080/auth-status', {
            withCredentials: true,
          });
          console.log('Auth status:', response.data);
          setIsAuthenticated(response.data.isAuthenticated);
          setUserProfile(response.data.userProfile);
        } catch (error) {
          console.error('Error checking auth status:', error);
          setIsAuthenticated(false); // Set to false if the API call fails
        }
      };
      checkAuth();
    }, []);
    

  useEffect(() => {
    const loadGoogleTranslateScript = () => {
      if (!document.querySelector('#google-translate-script')) {
        const script = document.createElement('script');
        script.id = 'google-translate-script';
        script.type = 'text/javascript';
        script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
        script.async = true;
        document.body.appendChild(script);
      }
    };

    window.googleTranslateElementInit = () => {
      if (googleTranslateRef.current && window.google) {
        new window.google.translate.TranslateElement(
          {
            pageLanguage: 'en',
            includedLanguages: 'af,ach,ak,am,ar,az,be,bg,bn,bs,ca,cs,da,de,el,en,es,et,eu,fa,fi,fr,gl,hi,hr,hu,id,it,iw,ja,ko,lt,lv,ms,mt,nl,no,pl,pt,ro,ru,sk,sl,sr,sv,sw,ta,th,tr,uk,vi,zh-CN,zh-TW',
            layout: window.google.translate.TranslateElement.InlineLayout.VERTICAL,
          },
          googleTranslateRef.current
        );
      }
    };

    loadGoogleTranslateScript();
  }, []);

  const handleLogin = () => {
    window.location.href = 'http://localhost:8080/login';
  };

  const handleLogout = () => {
    window.location.href = 'http://localhost:8080/logout';
  };

  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  // Define the handlePasswordSubmit function
  const handlePasswordSubmit = async () => {
    try {
      const response = await axios.post(
        'https://coffeecatsproject3-0okd.onrender.com/api/validate-manager',
        { password },
        { withCredentials: true }
      );

      if (response.data.valid) {
        setMessage(`Switched to ${passwordModal.view} view`);
      } else {
        setMessage('Invalid password');
      }
    } catch (error) {
      setMessage('Error validating password. Please try again.');
      console.error('Error:', error);
    } finally {
      setPassword('');
      setPasswordModal({ visible: false, view: '' });
      setShowMessageModal(true);
    }
  };

  return (
  <AuthProvider>
    <Router>
      <div className={`app-container ${isOpen ? 'sidebar-open' : ''}`}>
        <Header
          isOpen={isOpen}
          toggleNav={toggleNav}
          isAuthenticated={isAuthenticated}
          userProfile={userProfile}
          handleLogin={handleLogin}
          handleLogout={handleLogout}
        />
        <Sidebar isOpen={isOpen} toggleNav={toggleNav} />
        <main className="content-container">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
          </Routes>
        </main>

        {showMessageModal && (
          <div className="message-modal fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded shadow-md">
              <p className="text-center text-lg mb-4">{message}</p>
              <button
                onClick={() => setShowMessageModal(false)}
                className="bg-red-500 text-white px-4 py-2 rounded"
              >
                Understood
              </button>
            </div>
          </div>
        )}

        {passwordModal.visible && (
          <div className="fixed-modal flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white p-6 rounded shadow-md">
              <h3 className="text-lg font-bold mb-4">
                Enter Password for {passwordModal.view} Access
              </h3>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === 'Enter') {
                    handlePasswordSubmit();
                  }
                }}
                className="w-full mb-4 p-2 border rounded"
              />
              <div className="flex justify-end gap-4">
                <button
                  onClick={() => setPasswordModal({ visible: false, view: '' })}
                  className="bg-gray-300 text-black px-4 py-2 rounded"
                >
                  Cancel
                </button>
                <button
                  onClick={handlePasswordSubmit}
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Router>
    </AuthProvider>
  );
};

export default App;
