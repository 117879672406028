import React, {useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { useAuth } from './authContext';
import { useNavigate } from 'react-router-dom';

import './Dashboard.css';

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);

const Dashboard = () => {
  const [events, setEvents] = useState([]); // Calendar events
  const [selectedDate, setSelectedDate] = useState(null); // Currently selected date
  const [selectedEvent, setSelectedEvent] = useState(null); // Currently selected event
  const [availabilityModal, setAvailabilityModal] = useState({
    visible: false,
    action: null,
    event: null,
  });
  const [notification, setNotification] = useState(null); 
  const [copiedEvent, setCopiedEvent] = useState(null);


  const [allDay, setAllDay] = useState(false);
  const [error, setError] = useState('');
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login');
    } else {
      fetchEvents();
    }
  }, [user, navigate]);
  
  const fetchEvents = async () => {
    try {
      const response = await fetch('http://localhost:8080/events', {
        credentials: 'include',
      });
      const data = await response.json();
      setEvents(data);
    } catch (error) {
      console.error('Error fetching events:', error);
      showNotification('Failed to load events.');
    }
  };

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(null), 3000); // Clear after 3 seconds
  };

  const handleAddAvailability = () => {
    setAvailabilityModal({ visible: true, action: 'add', event: null });
    setAllDay(false); 
  };

  const handleEditAvailability = () => {
    if (!selectedEvent) {
      alert('Please select an event to edit.');
      return;
    }
    setAvailabilityModal({ visible: true, action: 'edit', event: selectedEvent });
    setAllDay(selectedEvent.allDay || false); 
  };

  const handleDeleteAvailability = async () => {
    if (!selectedEvent) {
      alert('Please select an event to delete.');
      return;
    }
  
    const confirmDelete = window.confirm(
      `Are you sure you want to delete availability for "${selectedEvent.title}"?`
    );
    if (confirmDelete) {
      try {
        await fetch(`http://localhost:8080/events/${selectedEvent.id}`, {
          method: 'DELETE',
          credentials: 'include',
        });
  
        setEvents(events.filter((e) => e.id !== selectedEvent.id));
        showNotification(`Event "${selectedEvent.title}" was deleted.`);
        setSelectedEvent(null); // Clear selection after deletion
      } catch (error) {
        console.error('Error deleting event:', error);
        showNotification('Failed to delete event.');
      }
    }
  };

  const handleSaveAvailability = async (newEvent) => {
    if (new Date(newEvent.start) > new Date(newEvent.end)) {
      alert('End date must be after the start date.');
      return;
    }
  
    try {
      if (availabilityModal.action === 'add') {
        const response = await fetch('http://localhost:8080/events', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include', // Include credentials for protected routes
          body: JSON.stringify(newEvent),
        });
  
        const savedEvent = await response.json();
        setEvents([...events, savedEvent]);
        showNotification(`Event "${newEvent.title}" was added.`);
      } else if (availabilityModal.action === 'edit') {
        const response = await fetch(`http://localhost:8080/events/${newEvent.id}`, {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          credentials: 'include',
          body: JSON.stringify(newEvent),
        });
  
        const updatedEvent = await response.json();
        setEvents(events.map((e) => (e.id === updatedEvent.id ? updatedEvent : e)));
        showNotification(`Event "${newEvent.title}" was edited.`);
      }
  
      setAvailabilityModal({ visible: false, action: null, event: null });
      setSelectedEvent(null); // Clear selection after saving
    } catch (error) {
      console.error('Error saving event:', error);
      showNotification('Failed to save event.');
    }
  };
  

  // Handle resizing of events
  const handleEventResize = async ({ event, start, end }) => {
    const now = moment();
    if (moment(start).isBefore(now)) {
      showNotification(`Cannot resize "${event.title}" to a time in the past.`);
      return;
    }
  
    const updatedEvent = { ...event, start: moment(start).toISOString(), end: moment(end).toISOString() };
  
    try {
      const response = await fetch(`http://localhost:8080/events/${event.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedEvent),
        credentials: 'include',
      });
  
      if (response.ok) {
        setEvents(events.map((e) => (e.id === event.id ? updatedEvent : e)));
        showNotification(`Event "${event.title}" was resized.`);
      } else {
        const error = await response.text();
        console.error('Error updating event:', error);
        showNotification('Error updating event.');
      }
    } catch (err) {
      console.error('Error:', err);
      showNotification('Error updating event.');
    }
  };

  useEffect(() => {
    const handleKeyDown = async (event) => {
      // Check for Ctrl+C
      if ((event.ctrlKey || event.metaKey) && event.key === 'c') {
        if (!selectedEvent) {
          showNotification('Please select an event to copy.');
          return;
        }
        setCopiedEvent(selectedEvent);
        showNotification(`Event "${selectedEvent.title}" copied.`);
      }
  
      // Check for Ctrl+V
      if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
        if (!copiedEvent) {
          showNotification('No event copied to paste.');
          return;
        }
  
        const now = moment();
        const newStart = moment.max(now, moment(copiedEvent.start)); // Ensure pasted event is in the future
        const duration = moment(copiedEvent.end).diff(moment(copiedEvent.start), 'minutes'); // Calculate duration
        const newEnd = newStart.clone().add(duration, 'minutes');
        const newEvent = {
          title: copiedEvent.title,
          start: newStart.toISOString(),
          end: newEnd.toISOString(),
          allDay: copiedEvent.allDay,
          type: copiedEvent.type,
        };
  
        try {
          // Call backend API to save the copied event as a new event
          const response = await fetch('http://localhost:8080/events', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(newEvent),
            credentials: 'include',
          });
  
          if (response.ok) {
            const createdEvent = await response.json();
            setEvents([...events, createdEvent]); // Add the new event to the local state
            showNotification(`Event "${createdEvent.title}" pasted.`);
          } else {
            const error = await response.text();
            console.error('Error pasting event:', error);
            showNotification('Error pasting event.');
          }
        } catch (err) {
          console.error('Error:', err);
          showNotification('Error pasting event.');
        }
      }
    };
  
    // Add event listener
    window.addEventListener('keydown', handleKeyDown);
  
    // Cleanup event listener on unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedEvent, copiedEvent, events]); // Dependencies
  
  

  const handleEventDrop = async ({ event, start, end }) => {
    const now = moment();
    if (moment(start).isBefore(now)) {
      showNotification(`Cannot move "${event.title}" to a time in the past.`);
      return;
    }
  
    const updatedEvent = { ...event, start: moment(start).toISOString(), end: moment(end).toISOString() };
  
    try {
      const response = await fetch(`http://localhost:8080/events/${event.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedEvent),
        credentials: 'include',
      });
  
      if (response.ok) {
        setEvents(events.map((e) => (e.id === event.id ? updatedEvent : e)));
        showNotification(`Event "${event.title}" was moved.`);
      } else {
        const error = await response.text();
        console.error('Error updating event:', error);
        showNotification('Error updating event.');
      }
    } catch (err) {
      console.error('Error:', err);
      showNotification('Error updating event.');
    }
  };

  const handleSelectEvent = (event) => {
    if (selectedEvent && selectedEvent.id === event.id) {
      // If the clicked event is already selected, unselect it
      setSelectedEvent(null);
      showNotification(`Event "${event.title}" was unselected.`);
    } else {
      // Otherwise, select the event
      setSelectedEvent(event);
    }
  };

  const handleSelectSlot = async ({ start, end }) => {
    const now = moment(); // Current date and time
    const today = moment().startOf('day');
    if (moment(start).isBefore(today)) {
      showNotification('Cannot create an event in the past.');
      return;
    }
    const title = prompt('Enter a title for your new event:');
  
    if (title) {
      const newEvent = {
        title,
        start: moment(start).toISOString(),
        end: moment(end).toISOString(),
        allDay: false,
        type: 'home', // Default type
      };
  
      try {
        const response = await fetch('http://localhost:8080/events', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(newEvent),
          credentials: 'include',
        });
  
        if (response.ok) {
          const createdEvent = await response.json();
          setEvents([...events, createdEvent]);
          showNotification(`Event "${title}" was created.`);
        } else {
          const error = await response.text();
          console.error('Error creating event:', error);
          showNotification('Error creating event.');
        }
      } catch (err) {
        console.error('Error:', err);
        showNotification('Error creating event.');
      }
    }
  };
  
  

  return (
    <div className="dashboard-container">
      <div className="dashboard-main">
        {/* Calendar */}
        <div className="dashboard-calendar">
          <DnDCalendar
            localizer={localizer}
            events={events.map((event) => ({
              ...event,
              start: moment(event.start).toDate(),
              end: moment(event.end).toDate(),
            }))}
            startAccessor="start"
            endAccessor="end"
            style={{ height: '80vh' }}
            selectable
            resizable
            onSelectSlot={handleSelectSlot} // Use the updated function
            onSelectEvent={handleSelectEvent}
            onEventDrop={handleEventDrop}
            onEventResize={handleEventResize}
            eventPropGetter={(event) => {
              let backgroundColor;
              if (event.type === 'home') {
                backgroundColor = '#1E90FF'; // Blue for Home
              } else if (event.type === 'away') {
                backgroundColor = '#FF4500'; // Orange for Away
              } else if (event.type === 'both') {
                backgroundColor = '#32CD32'; // Green for Home & Away
              }

              const isSelected = selectedEvent && selectedEvent.id === event.id;
              return {
                style: {
                  backgroundColor: isSelected ? '#FFD700' : backgroundColor,
                  color: '#fff',
                  borderRadius: '5px',
                  border: isSelected ? '1.5px solid #000' : 'none',
                },
              };
            }}
          />
        </div>

        {/* Sidebar */}
        <div className="dashboard-sidebar">
          <div className="dashboard-sidebar-header">
            <span>{user?.name}'s Dashboard</span>
            <img
              src={user?.picture || 'default-profile.jpg'}
              alt="Profile"
              className="dashboard-profile-pic"
            />
          </div>
          <p>Add, edit, or delete availability below:</p>
          <button className="sidebar-button" onClick={handleAddAvailability}>
            Add Availability
          </button>
          <button className="sidebar-button" onClick={handleEditAvailability}>
            Edit Availability
          </button>
          <button className="sidebar-button" onClick={handleDeleteAvailability}>
            Delete Availability
          </button>
          <button
            className="sidebar-button"
            onClick={() => {
              if (!selectedEvent) {
                alert('Please select an event to copy.');
                return;
              }
              setCopiedEvent(selectedEvent);
              showNotification(`Event "${selectedEvent.title}" copied.`);
            }}
          >
            Copy Event
          </button>
          <button
            className="sidebar-button"
            onClick={() => {
              if (!copiedEvent) {
                alert('No event copied to paste.');
                return;
              }
              const now = moment();
              const newStart = moment.max(now, moment(copiedEvent.start)); // Ensure new event starts now or later
              const duration = moment(copiedEvent.end).diff(moment(copiedEvent.start), 'minutes'); // Calculate event duration
              const newEnd = newStart.clone().add(duration, 'minutes');
              const newEvent = {
                ...copiedEvent,
                id: Date.now(), // New unique ID
                start: newStart.toISOString(),
                end: newEnd.toISOString(),
              };
              setEvents([...events, newEvent]);
              showNotification(`Event "${copiedEvent.title}" pasted.`);
            }}
          >
            Paste Event
          </button>
        </div>
      </div>

      {/* Modal for Add/Edit Availability */}
      {availabilityModal.visible && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>
              {availabilityModal.action === 'add'
                ? 'Add Availability'
                : 'Edit Availability'}
            </h3>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                const form = e.target;
                const isAllDay = allDay;
                const today = moment().startOf('day'); // Current day at midnight
                if (isAllDay) {
                  const singleDate = moment(form.singleDate.value);
                  if (singleDate.isBefore(today)) {
                    alert('All-day events cannot be in the past.');
                    return;
                  }
                  const newEvent = {
                    id: availabilityModal.event?.id || Date.now(),
                    title: form.title.value,
                    allDay: isAllDay,
                    start: singleDate.format('YYYY-MM-DD'),
                    end: singleDate.format('YYYY-MM-DD'),
                    type: form.type.value,
                  };
                  handleSaveAvailability(newEvent);
                } else {
                  const start = moment(form.start.value);
                  const end = moment(form.end.value);
                  if (start.isAfter(end)) {
                    alert('End date and time must be after the start date and time.');
                    return;
                  }
                  if (start.isBefore(moment())) {
                    alert('Events cannot be scheduled in the past.');
                    return;
                  }
                  const newEvent = {
                    id: availabilityModal.event?.id || Date.now(),
                    title: form.title.value,
                    allDay: isAllDay,
                    start: start.toISOString(),
                    end: end.toISOString(),
                    type: form.type.value,
                  };
                  handleSaveAvailability(newEvent);
                }
              }}
            >
              <label>
                Title:
                <input
                  type="text"
                  name="title"
                  defaultValue={availabilityModal.event?.title || ''}
                  required
                />
              </label>
              <label>
                All Day:
                <input
                  type="checkbox"
                  name="allDay"
                  checked={allDay}
                  onChange={(e) => setAllDay(e.target.checked)}
                />
              </label>

              {allDay ? (
                <label>
                  Date:
                  <input
                    type="date"
                    name="singleDate"
                    defaultValue={
                      availabilityModal.event?.start &&
                      moment(availabilityModal.event.start).format('YYYY-MM-DD')
                    }
                    required
                  />
                </label>
              ) : (
                <>
                  <label>
                    Start Date & Time:
                    <input
                      type="datetime-local"
                      name="start"
                      defaultValue={
                        availabilityModal.event?.start &&
                        moment(availabilityModal.event.start).format('YYYY-MM-DDTHH:mm')
                      }
                      required
                    />
                  </label>
                  <label>
                    End Date & Time:
                    <input
                      type="datetime-local"
                      name="end"
                      defaultValue={
                        availabilityModal.event?.end &&
                        moment(availabilityModal.event.end).format('YYYY-MM-DDTHH:mm')
                      }
                      required
                    />
                  </label>
                </>
              )}
              <label>
                Type:
                <select
                  name="type"
                  defaultValue={availabilityModal.event?.type || 'home'}
                  required
                >
                  <option value="home">Home</option>
                  <option value="away">Away</option>
                  <option value="both">Home & Away</option>
                </select>
              </label>
              <div className="modal-actions">
                <button type="submit" className="save-button">
                  Save
                </button>
                <button
                  type="button"
                  className="cancel-button"
                  onClick={() =>
                    setAvailabilityModal({
                      visible: false,
                      action: null,
                      event: null,
                    })
                  }
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {notification && (
        <div className="notification-popup">
          <p>{notification}</p>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
