// Landing.js
import React from 'react';
import './Landing.css';
import Puckdrop from './videos/puckdrop.mp4';

const Landing = () => {
  return (
    <div className="landing-container">
      {/* Main Landing Page Content */}
      <div className="landing-content">
        <video autoPlay loop muted className="video-background">
          <source src={Puckdrop} type="video/mp4" />
        </video>
        <div className="bottom-blur"></div>

          <div className="overlay-text">
            <p>Welcome to RinkSync, your hub for all things scheduling!</p>
          </div>
      </div>
    </div>
  );
};

export default Landing;